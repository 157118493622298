@use '@/styles/utils/mixins.scss' as *;

.contactFormWrapper {
  font-family: 'Fakt Pro', sans-serif;
  width: 100%;
  max-width: 26rem;
  padding: 2.5rem;
  border-radius: 2.5rem;
  background-color: $neutral02;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  height: max-content;
  transition: all 0.2s ease-in-out;
  @include tab() {
    padding: 1.875rem;
  }
  .contactFormTitle {
    font-family: 'Fakt Pro', sans-serif;
    background-color: transparent;
    border: 0;
    outline: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 500;
    color: $dark;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 0.25rem 0;
  }
  .contactFormDescription {
    font-size: 0.875rem;
    color: $primary;
    font-weight: 500;
    padding-right: 1.375rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5rem;
      font-weight: 500;
      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2.125rem;
    }
    h5 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }
    h6 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-top: 2.5rem;
    transition: all 0.2s ease-in-out;
  }
  .contactFormField {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }
  .acceptTerms {
    font-size: 0.875rem;
    font-weight: 500;
    color: $primary;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .float-label {
      .as-label,
      .ant-select-selector,
      .float-input {
        background: $neutral02 !important;
      }
    }
    .customCheckboxLabel {
      font-size: 0.875rem !important;
    }
  }
}
